import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette:{
    primary:{
      main: '#267DCE',
      dark: '#0A0963',
      light: '#69C2D6'
    },
    secondary:{
      main: '#F78B1E',
      dark: '#F46221',
      light: '#F2B666'
      // main: '#F9E9E1',
      // dark: '#E06A8D',
      // light: '#F9E9E1'
    },
    alt:{
      main: '#7AE5CA',
      dark: 'C7FFF0',
      light: '#C7FFF0'
    },
    verify:{
      main: '#159B78',
      dark: '#057A69',
      light: '#40C69F'
    },
    wip:{
      main: '#F8A01C',
      dark: '#F78B1E',
      light: '#F2B666'
    },
    blank:{
      main:'#FFFFFF',
      dark:'#EEEEEE'
    },
    background: {
      main: '#FFFFFF',
      secondary: '#F7F7F7',
      dark: '#03032E'
    },
    type:{
      dark: '#0A0963',
      mid: '#267DCE'
    },
    card:{
      bg: '#fff'
    },
    light:{
      blue:'#AEECF8',
      red:'#F2CCD4'
    },
    mid:{
      blue:'#BFFBFF',
      red:'#F9E9E1',
    },
    dark:{
      blue:'#019BBC',
      red:'#E06A8D'
    }
  },
  typography: {
    fontFamily:'DM Sans',
    fontSize: 16,
    h1: {
      fontFamily:'DM Sans',
      fontSize:72,
      fontWeight:600,
      color:'#0A0963'
    },
    h2: {
      fontFamily:'DM Sans',
      fontSize:36,
      color:'#777777'
    },
    h3: {
      fontFamily:'DM Sans',
      fontSize:28,
      margin:0,
      color:'#777777'
    },
    h4: {
      fontFamily:'DM Sans',
      fontSize:24,
      fontWeight:500,
      color:'#777777'
    },
    medium: {
      fontFamily:'DM Sans',
      fontSize:20,
      fontWeight:500,
      color:'#777777'
    },
    default: {
      fontFamily:'DM Sans',
      fontSize:20,
      margin:0,
      color:'#777777'
    }
  },
  ui:{
    buttons:{
      fill:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#000000',
        padding: '8px 24px 8px 24px',
        borderRadius: 8,
        fontSize: 18,
        fontWeight:500,
        letterSpacing:1.2,
        fontFamily: 'DM Sans',
        color:'#ffffff',
        cursor:'pointer',
        textDecoration:'none'
      },
      outline:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        border:'1px solid #000000',
        padding: '8px 24px 8px 24px',
        borderRadius: 8,
        fontSize: 20,
        fontFamily: 'DM Sans',
        color:'#000000',
        cursor:'pointer',
        textDecoration:'none'
      },
      sub:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        fontSize: 13,
        fontFamily: 'DM Sans',
        textTransform:'uppercase',
        letterSpacing:1.2,
        fontWeight:600,
        color:'#000000',
        cursor:'pointer',
        textDecoration:'none'
      },
      default:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        fontSize: 20,
        fontFamily: 'DM Sans',
        fontWeight:500,
        color:'#000000',
        cursor:'pointer',
        textDecoration:'none'
      }
    }
  },
  
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: 'calc(100%-64px)',
          background: '#ffffffee',
          padding: 32
        },
      },
    },
    MuiBox: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position:'fixed',
            top:0,
          },
        },
      ]
    },

    MuiGrid: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position: 'fixed',
            top: 0,
          },
        },
        {
          props: { variant: 'centerWidth' },
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems:'flex-start'
          },
        },
        {
          props: { variant: 'centerHeight' }, 
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        },
        {
          props: { variant: 'center' },
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'space-between' },
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center'
          },
        },
      ],
    },

    MuiButton: {
      styleOverrides:{
        root:{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          fontSize: 20,
          fontFamily: 'DM Sans',
          fontWeight:400,
          lineHeight:1,
          // color:'#000000',
          cursor:'pointer',
          textTransform:'none',
          textDecoration:'none',
        }
      },
      variants: [
        {
          props: { variant: 'text' },
          style: {
            fontWeight:500,
            fontSize:16
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            // background:'#267DCE',
            padding: '16px 24px 16px 24px',
            borderRadius: 8,
            fontSize: 18,
            fontWeight:500,
            letterSpacing:1.2,
            // fontFamily: 'DM Sans',
            color:'#ffffff',
            cursor:'pointer',
            // textDecoration:'none',
            whiteSpace:'nowrap'
          },
        },
        {
          props: { variant: 'contained-thin' },
          style: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            background:'#267DCE',
            padding: '8px 24px 8px 24px',
            borderRadius: 8,
            fontSize: 18,
            fontWeight:500,
            letterSpacing:1.2,
            fontFamily: 'DM Sans',
            color:'#ffffff',
            cursor:'pointer',
            textDecoration:'none',
            whiteSpace:'nowrap',
            transition:'.25s',
            '&&:hover':{
              background:'#0A0963'
            }
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            // background:'#267DCE',
            padding: '16px 24px 16px 24px',
            borderRadius: 8,
            fontSize: 18,
            fontWeight:500,
            letterSpacing:1.2,
            // fontFamily: 'DM Sans',
            // color:'#ffffff',
            cursor:'pointer',
            // textDecoration:'none',
            whiteSpace:'nowrap'
          },
        },
        {
          props: { variant: 'outlined-thin' },
          style: {
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            border:'1px solid #69C2D6',
            padding: '8px 24px 8px 24px',
            borderRadius: 8,
            fontSize: 18,
            fontWeight:500,
            letterSpacing:1.2,
            fontFamily: 'DM Sans',
            color:'#267DCE',
            cursor:'pointer',
            textDecoration:'none',
            whiteSpace:'nowrap',
            transition:'.25s',
            '&&:hover':{
              // border:'1px solid #267DCE',
              background:'#69C2D620'
            }
          },
        },
        {
          props: {variant: 'sub'},
          style: {
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            fontSize: 13,
            fontFamily: 'DM Sans',
            textTransform:'uppercase',
            letterSpacing:1.2,
            fontWeight:600,
            padding: '8px 16px',
            color:'#000000',
            cursor:'pointer',
            textDecoration:'none'    
          }
        },
        {
          props: { variant: 'menu' },
          style: {
            padding: '24px 0px',
            width: '100%',
            display:'flex',
            justifyContent:'center',
            color:'inherit',
            transition:'.25s',
            '&&:hover':{
              background:'#267DCE'
            }
          },
        },
        {
          props: { variant: 'nohover' },
          style: {
            fontSize: 16,
            fontWeight:600,
            '&&:hover':{
              background: 'transparent',
            }
          }
        }
      ]
    },
    
    MuiDrawerHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        title: {
          fontWeight: 'bold',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '16px 0',
          '& .MuiInputBase-input': {
            backgroundColor: 'white',
          },
        },
      },
    },
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    footer: 200,
    nav: 100,
    navSticky:50
  },

  breakpoints:{
    keys:[
      'ts',
      'xs',
      'sm',
      'md',
      'lg',
      'xl'
    ],
    values:{
      ts: 0,
      xs: 370,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  }
})






// ALL MUI COMPONENTS
// MuiAppBar
// MuiAvatar
// MuiBackdrop
// MuiBadge
// MuiBottomNavigation
// MuiButton
// MuiButtonBase
// MuiCard
// MuiCardActions
// MuiCardContent
// MuiCardHeader
// MuiCardMedia
// MuiCheckbox
// MuiChip
// MuiCircularProgress
// MuiCollapse
// MuiContainer
// MuiCssBaseline
// MuiDialog
// MuiDialogActions
// MuiDialogContent
// MuiDialogContentText
// MuiDialogTitle
// MuiDivider
// MuiDrawer
// MuiFab
// MuiFilledInput
// MuiFormControl
// MuiFormControlLabel
// MuiFormGroup
// MuiFormHelperText
// MuiFormLabel
// MuiGrid
// MuiIconButton
// MuiInput
// MuiInputAdornment
// MuiInputBase
// MuiInputLabel
// MuiLinearProgress
// MuiLink
// MuiList
// MuiListItem
// MuiListItemAvatar
// MuiListItemIcon
// MuiListItemSecondaryAction
// MuiListItemText
// MuiMenu
// MuiMenuItem
// MuiMobileStepper
// MuiModal
// MuiNativeSelect
// MuiOutlinedInput
// MuiPaper
// MuiPopover
// MuiRadio
// MuiScopedCssBaseline
// MuiSelect
// MuiSkeleton
// MuiSlider
// MuiSnackbar
// MuiSnackbarContent
// MuiSpeedDial
// MuiSpeedDialAction
// MuiSpeedDialIcon
// MuiStep
// MuiStepButton
// MuiStepConnector
// MuiStepContent
// MuiStepIcon
// MuiStepLabel
// MuiStepper
// MuiSvgIcon
// MuiSwitch
// MuiTab
// MuiTable
// MuiTableBody
// MuiTableCell
// MuiTableContainer
// MuiTableFooter
// MuiTableHead
// MuiTablePagination
// MuiTableRow
// MuiTableSortLabel
// MuiTabs
// MuiTextareaAutosize
// MuiTextField
// MuiToggleButton
// MuiToggleButtonGroup
// MuiToolbar
// MuiTooltip
// MuiTouchRipple
// MuiTypography
// MuiUseMediaQuery
// MuiZoom