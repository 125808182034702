import { useEffect, useState } from 'react';
import { useRoutes, useNavigate } from 'react-router';
import { auditRoutes, guestRoutes, trialRoutes, unverifiedRoutes, userRoutes } from './routes';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { fetchToken, firebase } from './firebase';
import { Button, Grid, Typography, responsiveFontSizes } from '@mui/material';
import { useAuth } from './context/authContext';
import { api_endpoint, auth_request_headers } from './lib/Backend';
import axios from 'axios';
import Loading from './components/ui/Loading';
import { useFetchSubscription } from './hooks/mutations';
import { AddSVG } from './assets/icons';
import RouteMiddleware from './lib/RouteMiddleware';

function App() {
  const [role, setRole] = useState(null);
  // const [topBanner, setTopBanner] = useState(true);

  const userContent = useRoutes(userRoutes);
  const auditContent = useRoutes(auditRoutes);
  const unverifiedContent = useRoutes(unverifiedRoutes);
  const guestContent = useRoutes(guestRoutes);
  const responsiveTheme = responsiveFontSizes(theme);
  const authContext = useAuth();
  const subscription = useFetchSubscription();
  const navigate = useNavigate();

  const fetchRole = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/role/', {headers});
      setRole(response.data);
    } catch (error) {
      if (authContext.user) {
        if (authContext.user.emailVerified) {
          console.log('auth email verified', authContext.user.emailVerified)
          setRole({user_role: 'audit', status: 'PendingSubscription'});
        } else {
          setRole({user_role: 'unverified', status: 'UnverifiedAccount'})
        }
        
      } else {
        setRole({user_role: 'guest'});
      }
      console.log('Error fetching role', error);
      if (error.code === "ERR_NETWORK") {
        navigate('network-error')
      }
    }
  }

  useEffect(() => {
    if (authContext) {
      fetchRole();
    }
    
  }, [authContext.role, authContext.user])

    
  return (
    
    <ThemeProvider theme={theme}>
      <>
        {/* <TopBanner
          topBanner={topBanner}
          setTopBanner={setTopBanner}
          theme={theme}
        /> */}
       
        { role &&
          (role.user_role === 'legal_firm' || role.user_role === 'audit') // Must accept either legal_firm or audit roles since auditContent is used with both role types
            ? <ContentGate message="legal firm" content={userContent} pendingContent={auditContent} role={role} />
            : null
        }
        { role &&
          role.user_role === 'lawyer'
            ? <ContentGate message="lawyer" content={userContent} pendingContent={userContent} role={role} />
            : null
        }
        { role &&
          role.user_role === 'client'
            ? <ContentGate message="client" content={userContent} pendingContent={userContent} role={role} />
            : null
        }
        { role &&
          role.user_role === 'unverified'
            ? <ContentGate message="unverified" content={userContent} pendingContent={unverifiedContent} role={role} />
            : null
        }
        {/* { role &&
          role.user_role === 'audit'
            ? <ContentGate message="auditContent" content={auditContent} role={role} />
            : null
        } */}
        { role &&
          role.user_role === 'guest'
            ? guestContent
            : null
        }
        { !role ||
          !role.user_role
            ? <Loading />
            : null
        }
      </>
    </ThemeProvider>
  );
}

export default App;

// Check for subscription status and deliver content accordingly
const ContentGate = (props) => {
  const { message, content, pendingContent, role } = props;

  return (
    role.status === 'Active'
      ? 
        content
        // content.map((route, index) => (
        //   <RouteMiddleware
        //     key={index}
        //     path={route.path}
        //     title={route.title}
        //     component={route.component}
        //     exact={route.exact}
        //   />
        // ))   
      : 
        pendingContent
        // pendingContent.map((route, index) => (
        //   <RouteMiddleware
        //     key={index}
        //     path={route.path}
        //     title={route.title}
        //     component={route.component}
        //     exact={route.exact}
        //   />
        // ))
  )
}

// const TopBanner = (props) => {
//   const { topBanner, setTopBanner, theme } = props;

//   return (
//       topBanner &&
//         <Grid
//           variant="space-between"
//           container
//           sx={{
//             display:{ts:'flex', sm:'none'},
//             padding:2,
//             background:theme.palette.primary.dark,
//             height:66
//           }}
//         >
//           <Grid
//             variant="center"
//           >
//             <Typography
//               style={{
//                 color:'#ffffff',
//                 fontSize:14
//               }}
//             >
//               Get the app
//             </Typography>
//             <a
//               href="https://play.google.com/store/apps/details?id=com.signedpic.signedpic"
//               target="_blank"
//             >
//               <img
//                 src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? "/images/GetIt-PlayStore.png" : "https://static.signedpic.com/static/images/GetIt-PlayStore.png"}
//                 alt="Google Play Store button"
//                 style={{
//                   height:30,
//                   margin:'0 8px'
//                 }}
//               />
//             </a>
//             <a
//               href="https://apps.apple.com/us/app/signedpic/id6451056693"
//               target="_blank"
//             >
//               <img
//                 src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? "/images/GetIt-AppStore.png" : "https://static.signedpic.com/static/images/GetIt-AppStore.png"}
//                 alt="App Store button"
//                 style={{
//                   height:30,
//                   margin:'0 8px'
//                 }}
//               />
//             </a>
//           </Grid>
//           <Grid
//             variant="center"
//             onClick={() => setTopBanner(false)}
//             style={{
//               padding:2,
//               cursor:'pointer'
//             }}
//           >
//             <AddSVG height={14} width={14} color1="#ffffff" style={{width:14, height:14, transform:'rotate(45deg)'}} />
//           </Grid>
            
//         </Grid>

//   )

// }

