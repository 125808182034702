import { Grid, Typography } from '@mui/material'
import React from 'react'
import LottiePlayer from './LottiePlayer'
import SignedPicSpinner from '../../assets/lottie/SignedSpinner2_brand-colors_BM.json'

const Loading = () => {
  return (
    <Grid
      variant="center"
      flexDirection="column"
      style={{
        height:'75vh'
      }}
    >
      <LottiePlayer
        data={SignedPicSpinner}
        height={250}
        width={250}
      />
      {/* <Typography variant="h1">
        Loading...
      </Typography> */}
    </Grid>
  )
}

export default Loading