import { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { api_endpoint, auth_request_headers } from "../lib/Backend";
import axios from "axios";




// ==========================================================================================================================
// ==========================================================================================================================
//      USER API
// ==========================================================================================================================
// ==========================================================================================================================

// ---------------------
// READ / GET OPERATIONS
// ---------------------
// Get role for the current user
export const useFetchRole = () => {
  const [role, setRole] = useState();
  const authContext = useAuth();
  const fetchRole = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/role/', {headers});
      setRole(response.data);
      authContext.setRole(response.data);
    } catch (error) {
      if (authContext.user) {
        setRole({user_role: 'audit'});
      } else {
        setRole({user_role: 'guest'});
      }
      console.log('Error fetching role', error);
    }
  }

  useEffect(() => {
    if (authContext.setRole) {
      fetchRole();
    }
  }, [authContext.setRole])

  return role ? role : null

}

// Get list of all subusers for the current user
export const useFetchSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();
  const fetchSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/', {headers});
      setSubs(response.data.results);
      authContext.setSubs(response.data.results);
    } catch (error) {
      console.log('Error fetching subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}

// Get recursive list of all subs for the current firm
export const useFetchAllFirmSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();
  const fetchAllFirmSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?all_users=true', {headers});
      setSubs(response.data.results);
      // authContext.setSubs(response.data.results);
    } catch (error) {
      console.log('Error fetching subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchAllFirmSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}

// Get recursive list of all subs for the current firm
export const useFetchAllFirmClientSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();

  const fetchAllFirmClientSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?all_users=true&sub_user_role=client', {headers});
      setSubs(response.data.results);
      authContext.setSubClients(response.data.results);
    } catch (error) {
      console.log('Error fetching firm client subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchAllFirmClientSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}

// Get recursive list of all subs and the lawyers they are shared with for the current firm
export const useFetchAllFirmSharedClientSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();

  const fetchAllFirmSharedClientSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?all_users=true&sub_user_role=client&show_shares=True', {headers});
      setSubs(response.data.results);
      authContext.setSubClients(response.data.results);
    } catch (error) {
      console.log('Error fetching firm client subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchAllFirmSharedClientSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}


// Get recursive list of all subs for the current firm
export const useFetchAllFirmLawyerSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();

  const fetchAllFirmLawyerSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?all_users=true&sub_user_role=lawyer', {headers});
      setSubs(response.data.results);
      authContext.setSubLawyers(response.data.results);
    } catch (error) {
      console.log('Error fetching firm lawyer subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchAllFirmLawyerSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}

export const useFetchClientSubs = () => {
  const [subs, setSubs] = useState();
  const authContext = useAuth();
  const fetchSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?sub_user_role=client', {headers});
      setSubs(response.data.results);
      authContext.setSubClients(response.data.results);
    } catch (error) {
      console.log('Error fetching client subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}

export const useFetchLawyerSubs = () => {
  const [subLawyers, setSubLawyers] = useState();
  const authContext = useAuth();
  const fetchSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/?sub_user_role=lawyer', {headers});
      setSubLawyers(response.data.results);
      authContext.setSubLawyers(response.data.results);
    } catch (error) {
      console.log('Error fetching lawyer subs', error);
    }
  }

  useEffect(() => {
    if (authContext.setSubLawyers) {
      fetchSubs();
    }
    
  }, [authContext.setSubLawyers])

  return subLawyers ? subLawyers : null

}

export const useFetchClient = (clientId) => {
  const [client, setClient] = useState();
  const authContext = useAuth();
  const fetchClient = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/auth/subusers/${clientId}`, {headers});
      setClient(response.data.results);
      authContext.setClient(response.data.results);
    } catch (error) {
      console.log('Error fetching client', error);
    }
  }

  useEffect(() => {
    if (authContext.setSubLawyers && clientId) {
      fetchClient();
    }
    
  }, [authContext.setSubLawyers, clientId])

  return client ? client : null

}

export const useFetchLawyerClients = (lawyerId) => {
  const [clients, setClients] = useState();
  const authContext = useAuth();
  const fetchLawyerClients = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/auth/subusers/?nested_user=${lawyerId}`, {headers});
      setClients(response.data.results);
      authContext.setClient(response.data.results);
    } catch (error) {
      console.log('Error fetching lawyer clients', error);
    }
  }

  useEffect(() => {
    if (lawyerId) {
      fetchLawyerClients();
    }
    
  }, [lawyerId])

  return clients ? clients : null

}

// GET THE LIST OF SUBUSERS WHO THE CURRENT USER HAS SHARED
export const useFetchSharedClients = () => {
  const [shared, setShared] = useState();
  const fetchSharedClients = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/auth/usershare`, {headers});
      setShared(response.data);
    } catch (error) {
      console.log('Error fetching shared clients', error);
    }
  }

  useEffect(() => {
    fetchSharedClients();
  }, [])

  return shared ? shared : null

}

export const fetchSharedClient = async (principal, set) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.get(api_endpoint() + `/api/auth/subusers/${principal}?show_shares=True`, {headers});
    set(response.data);
    
  } catch (error) {
    console.log('Error fetching firm client subs', error);
  }
}

export const fetchLawyer = async (principal, set) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.get(api_endpoint() + `/api/auth/subusers/${principal}`, {headers});
    set(response.data);
    
  } catch (error) {
    console.log('Error fetching lawyer', error);
  }
}


// GET A SPECIFIC SUBUSER WHO THE CURRENT USER HAS SHARED
export const useFetchSharedClient = (id) => {
  const [shared, setShared] = useState();
  const fetchSharedClient = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/auth/usershare/${id}`, {headers});
      setShared(response.data);
    } catch (error) {
      console.log('Error fetching shared client', error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchSharedClient();
    }
  }, [id])

  return shared ? shared : null

}





// -----------------------
// CREATE / POST OPERATIONS
// -----------------------
export const postSharedClient = async (obj) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + '/api/auth/usershare/',
    {
      principal: obj.principal,
      shared_with: obj.shared_with
    }, {headers});

    return response.data;

  } catch (error) {
    console.log('Error posting shared client', error);
  }
}










// ---------------------
// DELETE OPERATIONS
// ---------------------
// Delete selected client or lawyer (logged in as admin legal_firm role)
// obj parameters are "entity_name" and "entity_email"
export const deleteSubUser = async (principal) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.delete(api_endpoint() + `/api/auth/delete_sub_user/${principal}`, {headers});
    return response;
  } catch (error) {
    console.error('Error deleting user', error);
  }
}











// ==========================================================================================================================
// ==========================================================================================================================
//      CHAT API
// ==========================================================================================================================
// ==========================================================================================================================

// ---------------------
// READ / GET OPERATIONS
// ---------------------
// Get list of all threads for the current user
export const useFetchThreads = () => {
  const [threads, setThreads] = useState();
  const authContext = useAuth();
  const fetchThreads = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/messaging/threads/',  {headers});
      setThreads(response.data.results);
    } catch (error) {
      console.log('Error fetching threads', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchThreads();
    }
  }, [authContext.user])

  return threads ? threads : null

}

// Get threads without any hooks. Use when threads needs to be reloaded and hooks won't suffice.
export const fetchThreads = async (setThreads) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.get(api_endpoint() + '/api/messaging/threads/',  {headers});
    setThreads(response.data.results);
  } catch (error) {
    console.log('Error fetching threads', error);
  }
}

// Get list of all messages from a specific thread for the current user
// Pass in the threadId, pageNumber, and pageSize as an object
export const useFetchMessages = (obj) => {
  const [messages, setMessages] = useState();
  const authContext = useAuth();

  const fetchMessages = async () => {
    try {
      var headers = await auth_request_headers();
      // const response = await axios.get(api_endpoint() + `/api/messaging/threads/${obj.threadId}/messages/?page=${obj.pageNumber}&page_size=${obj.pageSize}`,  {headers});
      const response = await axios.get(api_endpoint() + `/api/messaging/threads/${obj.threadId}/messages/`,  {headers}); // For testing with hard coded thread id only
      setMessages(response.data.results);
    } catch (error) {
      console.log('Error fetching messages', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchMessages();
    }
  }, [authContext.user])

  return messages ? messages : null

}

// Get messages without any hooks. Use when messages needs to be reloaded and hooks won't suffice.
export const fetchMessages = async (obj, setMessages) => {
  try {
    var headers = await auth_request_headers();
    // const response = await axios.get(api_endpoint() + `/api/messaging/threads/${obj.threadId}/messages/?page=${obj.pageNumber}&page_size=${obj.pageSize}`,  {headers});
    const response = await axios.get(api_endpoint() + `/api/messaging/threads/${obj.threadId}/messages/`,  {headers}); // For testing with hard coded thread id only
    setMessages(response.data.results);
  } catch (error) {
    console.log('Error fetching messages', error);
  }
}





// -----------------------
// CREATE / POST OPERATIONS
// -----------------------
export const postThread = async (sender, receiver) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + '/api/messaging/threads/',
    {
      sender: sender,
      receiver: receiver
    }, {headers});

    return response.data;

  } catch (error) {
    console.log('Error fetching client subs', error);
  }
}

export const postMessage = async (sender, receiver, threadId, message, setReload) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + `/api/messaging/threads/${threadId}/messages/`,
    {
      sender: sender,
      receiver: receiver,
      body:message
    }, {headers});
    if (setReload) {
      setReload(true);
    }
    return response.data;
  } catch (error) {
    console.log('Error sending message', error);
  }
}

// -----------------------
// UPDATE / PUT OPERATIONS
// -----------------------
export const putMarkMessageRead = async (threadId, messageId) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.put(api_endpoint() + `/api/messaging/threads/${threadId}/messages/${messageId}/mark-read/`, {}, {headers});
    return response.data;

  } catch (error) {
    console.log('Error marking messages as read', error);
  }
}














// ==========================================================================================================================
// ==========================================================================================================================
//      SUBSCRIPTION API
// ==========================================================================================================================
// ==========================================================================================================================

// ---------------------
// READ / GET OPERATIONS
// ---------------------
// Get the subscription for the current user
export const useFetchSubscription = () => {
  const [subscription, setSubscription] = useState();
  const fetchSubscription = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/payment/subscriptions/',  {headers});
      setSubscription(response.data[0]);
    } catch (error) {
      console.log('Error fetching subscription', error);
      // setSubscription({status:'incomplete'})
    }
  }

  useEffect(() => {
    fetchSubscription();
  }, [])

  return subscription ? subscription : null

}

// Redirect the user to the customer portal
export const fetchCustomerPortal = async () => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + "/api/payment/manage_subscriptions/", {}, {headers},);
    
    if (response.status == 201) {
        // window.location.href = response.data.url // Use to redirect the current tab/window
        window.open(response.data.url, '_blank');
    } else {
        console.log("Error redirecting to customer portal" + response);
    }
  } catch (error) {
    console.log('Error fetching subscription', error);
  }
}






// Get the usage for the current user
export const useFetchUsage = () => {
  const [usage, setUsage] = useState();
  const fetchUsage = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/usage/details/', {headers});
      setUsage(response.data);
    } catch (error) {
      console.log('Error fetching usage', error);
    }
  }

  useEffect(() => {
    fetchUsage();
  }, [])

  return usage ? usage : null

}


















// ==========================================================================================================================
// ==========================================================================================================================
//      USER FEEDBACK / INPUT API
// ==========================================================================================================================
// ==========================================================================================================================

// -----------------------
// CREATE / POST OPERATIONS
// -----------------------
export const postFeedback = async (obj) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + '/api/feedback/feedback/',
    {
      label: obj.label,
      content: obj.content
    }, {headers});

    return response.data;

  } catch (error) {
    console.log('Error posting feedback', error);
  }
}

export const postContact = async (obj) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + '/api/feedback/feedback/',
    {
      name: obj.name,
      email: obj.email,
      subject: obj.subject,
      message: obj.message
    }, {headers});

    return response.data;

  } catch (error) {
    console.log('Error posting contact message', error);
  }
}



















// ==========================================================================================================================
// ==========================================================================================================================
//      IMAGES API
// ==========================================================================================================================
// ==========================================================================================================================

// ---------------------
// READ / GET OPERATIONS
// ---------------------
// Get all images from a specified client by id (principal)
// obj parameters = {principal}
export const useFetchClientFiles = (obj) => {
  const [files, setFiles] = useState();
  const fetchFiles = async () => {
    try {
        var headers = await auth_request_headers();
        const response = await axios.get(api_endpoint() + `/api/auth/subusers/${obj.principal}/files`, {headers});
        setFiles(Array.from(Object.values(response.data["files"])));
        
    } catch (error) {
      console.log('Error: No files', error);
      if (error.response.status === 500) {

      }
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [])

  return files ? files : null
}

// Get the verification report of a specified client by client id (principal)
// obj parameters = {principal, file}
export const fetchReport = async (obj) => {
  try {
      var headers = await auth_request_headers();
      const response = await axios.post(api_endpoint() + `/api/auth/subusers/${obj.principal}/files/${obj.file}/pdf`, {}, {headers});
      // const response = await axios.get(api_endpoint() + `/api/auth/subusers/hAwy1NS98FdBvQdhGCYKca3QhFM2/files/ee65e891-db1d-44d0-b9e6-6c76174a9047/pdf`, {headers});
      return response.data;

  } catch (error) {
    if (error.response.status === 500) {

    }
    console.log('Error: No files', error);
  }
};


// obj parameters = {folder, file, thumbnail}
export const useFetchImage = (obj) => {
  const [image, setImage] = useState();
  const fetchImage = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/storage/retrieve/External : ${obj.folder}/${obj.file}/${obj.thumbnail && '?thumbnail=true'}`,  {headers});

      setImage(response.data[0]);
    } catch (error) {
      console.log('Error fetching image', error);
    }
  }

  useEffect(() => {
    fetchImage();
  }, [])

  return image ? image : null
}

// Get a list of all folders for the current user
export const useFetchFolders = () => {
  const [folders, setFolders] = useState();
  const fetchFolders = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/storage/list/`, {headers});
      setFolders(response.data);
    } catch (error) {
      console.log('Error fetching images', error);
    }
  }

  useEffect(() => {
    fetchFolders();
  }, [])

  return folders ? folders : null
}

// Get list of images for selected folder
// obj parameters = {folder, thumbnail}
export const useFetchFolderContent = (obj) => {
  const [files, setFiles] = useState();
  const fetchFolderContent = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/storage/list/${obj.folder}/`, {headers});
      const convertedResponse = Object.values(response.data.files);
      setFiles(convertedResponse);
    } catch (error) {
      console.log('Error fetching images', error);
    }
  }

  useEffect(() => {
    if (obj.folder) {
      fetchFolderContent();
    }
  }, [obj.folder])

  return files ? files : null
}

// Get specific image from a specific folder
// obj parameters = {folder, image, thumbnail}
export const useFetchFile = (obj) => {
  const [file, setFile] = useState();
  const fetchFile = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/storage/retrieve/${obj.folder}/${obj.image}/${obj.thumbnail ? '?thumbnail=true' : ''}`,  {headers, responseType:'blob'});
      setFile(response.data);
    } catch (error) {
      console.log('Error fetching images', error);
    }
  }

  useEffect(() => {
    if (obj.folder && obj.image) {
      fetchFile();
    }
  }, [obj.folder, obj.image])

  return file ? file : null
}

// Get note attached to a specific image if it exists FOR CLIENT
// obj parameters = {folder, image}
export const useFetchImageNote = (obj) => {
  const [note, setNote] = useState();
  const fetchNote = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/storage/retrieve_metadata/${obj.folder}/${obj.image}/`,  {headers});

      setNote(response.data);
    } catch (error) {
      console.log('Error fetching notes', error);
    }
  }

  useEffect(() => {
    if (obj.folder && obj.image) {
      fetchNote();
    }
  }, [obj.folder, obj.image])

  return note ? note : null
}

// Get note attached to a specific image if it exists FOR LAWYER/FIRM
// obj parameters = {clientId, image}
export const useFetchClientImageNote = (obj) => {
  const [note, setNote] = useState();
  const fetchNote = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + `/api/auth/subusers/${obj.clientId}/files/${obj.image}/metadata`, {headers});
      setNote(response.data);
    } catch (error) {
      console.log('Error fetching images', error);
    }
  }

  useEffect(() => {
    if (obj.folder && obj.image) {
      fetchNote();
    }
  }, [obj.folder, obj.image])

  return note ? note : null
}




// -----------------------
// CREATE / POST OPERATIONS
// -----------------------
// Add note to a specific image
// obj parameters = {folder, file}
export const postImageNote = async (obj) => {
  try {
    var headers = await auth_request_headers();
    const response = await axios.post(api_endpoint() + `/api/storage/store_metadata/${obj.folder}/${obj.image}/`,
    {
      title: obj.title
    }, {headers});

    return response.data;

  } catch (error) {
    console.log('Error posting note to image', error);
  }
}

