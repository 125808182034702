import { Suspense, lazy } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const ProductPage = Loadable(lazy(() => import('./pages/ProductPage')));
const ProcessPage = Loadable(lazy(() => import('./pages/ProcessPage')));
const PricingPage = Loadable(lazy(() => import('./pages/PricingPage')));
const ContactPage = Loadable(lazy(() => import('./pages/ContactPage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const RegisterPage = Loadable(lazy(() => import('./pages/RegisterPage')));
const UpdateLoginPage = Loadable(lazy(() => import('./pages/UpdateLoginPage')));
const FirmCreatePage = Loadable(lazy(() => import('./pages/FirmCreatePage')));
const ClientDashboardPage = Loadable(lazy(() => import('./pages/ClientDashboardPage')));
const DashboardPage = Loadable(lazy(() => import('./pages/DashboardPage')));
const DashboardAuditPage = Loadable(lazy(() => import('./pages/DashboardAuditPage')));
const LawyerDashboardPage = Loadable(lazy(() => import('./pages/LawyerDashboardPage')));
const PurchasePage = Loadable(lazy(() => import('./pages/PurchasePage')));
const PurchaseSuccessPage = Loadable(lazy(() => import('./pages/PurchaseSuccessPage')));
const FolderPage = Loadable(lazy(() => import('./pages/FolderPage')));
const VerificationGuestPage = Loadable(lazy(() => import('./pages/VerificationGuestPage')));
const SubscriptionPage = Loadable(lazy(() => import('./pages/SubscriptionPage')));
const ClientPage = Loadable(lazy(() => import('./pages/ClientPage')));
const BlogPage = Loadable(lazy(() => import('./pages/BlogPage')));
const EmailVerificationPage = Loadable(lazy(() => import('./pages/EmailVerificationPage')));
const NetworkErrorPage = Loadable(lazy(() => import('./pages/NetworkErrorPage')));
const NotFoundPage = Loadable(lazy(() => import('./pages/NotFoundPage')));

// Guest Routes
const PrivacyPolicyPage = Loadable(lazy(() => import('./pages/PrivacyPolicyPage')));
const TermsOfServicePage = Loadable(lazy(() => import('./pages/TermsOfServicePage')));
const ExamplePage = Loadable(lazy(() => import('./pages/ExamplePage')));
const PDFTemplate = Loadable(lazy(() => import('./components/print/PDFTemplate')));

export const guestRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'update-login', element: <UpdateLoginPage /> },
  { path: 'product', element: <ProductPage /> },
  { path: 'process', element: <ProcessPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'verify', element: <VerificationGuestPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: 'terms-and-conditions', element: <TermsOfServicePage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'blog/:blogId', element: <BlogPage /> },
  { path: 'network-error', element: <NetworkErrorPage /> },
  { path: 'ex/:exampleType', element: <ExamplePage /> },
  { path: 'ex', element: <ExamplePage /> },
  {
    path: '/*', element: <LandingPage />
  }
];

export const clientRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'update-login', element: <UpdateLoginPage /> },
  { path: 'product', element: <ProductPage /> },
  { path: 'process', element: <ProcessPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'verify', element: <VerificationGuestPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: 'terms-and-conditions', element: <TermsOfServicePage /> },
  { path: 'print', element: <PDFTemplate /> },
  { path: 'dashboard/', element: <DashboardPage /> },
  { path: 'dashboard/:menuId', element: <DashboardPage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'dashboard/:menuId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/clients/:clientId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/attorneys/:lawyerId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId/file', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId/file/:fileId', element: <DashboardPage /> },
  { path: 'blog/:blogId', element: <BlogPage /> },
  { path: 'network-error', element: <NetworkErrorPage /> },
  { path: 'ex/:exampleType', element: <ExamplePage /> },
  { path: 'ex', element: <ExamplePage /> },
  {
    path: '/*', element: <LandingPage />
  }
];

export const auditRoutes = [
  { path: '/', element: <DashboardAuditPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'update-login', element: <UpdateLoginPage /> },
  { path: 'product', element: <ProductPage /> },
  { path: 'process', element: <ProcessPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'firm-details', element: <FirmCreatePage /> },
  { path: 'dashboard/', element: <DashboardAuditPage /> },
  { path: 'dashboard/:menuId', element: <DashboardPage /> },
  { path: 'dashboard/clients/:clientId', element: <DashboardPage /> },
  { path: 'lawyer-dashboard', element: <LawyerDashboardPage /> },
  { path: 'purchase', element: <PurchasePage /> },
  { path: 'purchase/success/True', element: <PurchaseSuccessPage /> },
  { path: 'clients/:clientId', element: <ClientPage /> },
  { path: 'client-folder', element: <FolderPage /> },
  { path: 'verify', element: <VerificationGuestPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: 'terms-and-conditions', element: <TermsOfServicePage /> },
  { path: 'subscription', element: <SubscriptionPage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'blog/:blogId', element: <BlogPage /> },
  { path: 'network-error', element: <NetworkErrorPage /> },
  { path: '/*', element: <PricingPage /> },
  { path: 'ex/:exampleType', element: <ExamplePage /> },
  { path: 'ex', element: <ExamplePage /> },
];

export const unverifiedRoutes = [
  { path: '/', element: <EmailVerificationPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'update-login', element: <UpdateLoginPage /> },
  { path: 'product', element: <ProductPage /> },
  { path: 'process', element: <ProcessPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'firm-details', element: <FirmCreatePage /> },
  // { path: 'dashboard/', element: <EmailVerificationPage /> },
  // { path: 'dashboard/:menuId', element: <DashboardPage /> },
  // { path: 'dashboard/clients/:clientId', element: <DashboardPage /> },
  // { path: 'lawyer-dashboard', element: <LawyerDashboardPage /> },
  { path: 'purchase', element: <PurchasePage /> },
  { path: 'purchase/success/True', element: <PurchaseSuccessPage /> },
  // { path: 'clients/:clientId', element: <ClientPage /> },
  // { path: 'client-folder', element: <FolderPage /> },
  { path: 'verify', element: <VerificationGuestPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: 'terms-and-conditions', element: <TermsOfServicePage /> },
  { path: 'subscription', element: <SubscriptionPage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'blog/:blogId', element: <BlogPage /> },
  { path: 'network-error', element: <NetworkErrorPage /> },
  { path: 'ex/:exampleType', element: <ExamplePage /> },
  { path: 'ex', element: <ExamplePage /> },
  { 
    path: '/*', element: <EmailVerificationPage />
  },
];

export const userRoutes = [
  { path: '/', element: <DashboardPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'update-login', element: <UpdateLoginPage /> },
  { path: 'product', element: <ProductPage /> },
  { path: 'process', element: <ProcessPage /> },
  { path: 'pricing', element: <PricingPage /> },
  { path: 'contact', element: <ContactPage /> },
  { path: 'firm-details', element: <FirmCreatePage /> },
  { path: 'dashboard/', element: <DashboardPage /> },
  { path: 'dashboard/:menuId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/clients/:clientId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/clients/:clientId/uploads/:fileId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/attorneys/:lawyerId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId/file', element: <DashboardPage /> },
  { path: 'dashboard/:menuId/folder/:folderId/file/:fileId', element: <DashboardPage /> },
  { path: 'lawyer-dashboard', element: <LawyerDashboardPage /> },
  { path: 'purchase', element: <PurchasePage /> },
  { path: 'purchase/success/True', element: <PurchaseSuccessPage /> },
  { path: 'clients/:clientId', element: <ClientPage /> },
  { path: 'client-folder', element: <FolderPage /> },
  { path: 'verify', element: <VerificationGuestPage /> },
  { path: 'privacy-policy', element: <PrivacyPolicyPage /> },
  { path: 'terms-and-conditions', element: <TermsOfServicePage /> },
  { path: 'subscription', element: <SubscriptionPage /> },
  { path: 'check-email', element: <EmailVerificationPage /> },
  { path: 'blog/:blogId', element: <BlogPage /> },
  { path: 'network-error', element: <NetworkErrorPage /> },
  { path: 'ex/:exampleType', element: <ExamplePage /> },
  { path: 'ex', element: <ExamplePage /> },
  {
    path: '/*', element: <NotFoundPage />
  }
];